// src/Components/Education.js
import React from 'react';
import BachelorTranscript from '../Components/Images/Final Official Transcript.pdf';
import DiplomaTranscript from '../Components/Images/Transcript Haider Sana.pdf';

const Education = () => {
  return (
    <section id="education" className="education">
      <h2 className="numbered-heading">Education</h2>
      <div className="education-item">
        <h3>Bachelor of Information Technology</h3>
        <p>La Trobe University, Melbourne</p>
        <p>July 2022 - June 2024</p>
        <p>Specialized in Software Engineering with distinction.</p>
        <a 
          href={BachelorTranscript} 
          download="Bachelor_Transcript.pdf" 
          className="transcript-link"
        >
          Download Transcript
        </a>
      </div>
      <div className="education-item">
        <h3>Diploma of Information Technology</h3>
        <p>La Trobe College, Melbourne</p>
        <p>June 2021 - June 2022</p>
        <p>Achieved high distinction in IT studies.</p>
        <a 
          href={DiplomaTranscript} 
          download="Diploma_Transcript.pdf" 
          className="transcript-link"
        >
          Download Transcript
        </a>
      </div>
    </section>
  );
};

export default Education;
