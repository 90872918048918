// src/components/About.js
import React from 'react';

function About() {
  return (
    <section id="about">
      <h2 className="numbered-heading"> About Me
      </h2>
      <p>
        I'm a web developer passionate about crafting engaging digital experiences. I focus on building intuitive, accessible, and high-performance web solutions.
      </p>
      <p>
        My expertise spans frontend, backend, and full-stack development, blending creativity with technical skill to bring innovative ideas to life.
      </p>
      <p>
        Let's collaborate and create something impactful together!
      </p>
    </section>
  );
}

export default About;

