// src/components/Header.js
import React from 'react';
import logo from './Images/HS Logo.png'; // Adjust the path according to your file structure

function Header() {
  return (
    <header>
      <nav>
        <div className="logo">
          <a href="/" aria-label="home">
            <img src={logo} alt="HS Logo" className="logo-image" />
          </a>
        </div>
        <div className="nav-links">
          <a href="#about" className="nav-link">About</a>
          <a href="#experience" className="nav-link">Experience</a>
          <a href="#work" className="nav-link">Work</a>
          <a href="#contact" className="nav-link">Contact</a>
          <a href="/Haider Sana Resume.pdf" className="nav-link button" download="Haider Sana Resume.pdf">Resume</a>
        </div>
      </nav>
    </header>
  );
}

export default Header;

