// src/components/Hero.js
import React, { useEffect, useState } from 'react';
import profileImage from './Images/profile.jpg';

const sentences = [
  "I build innovative web experiences.",
  "Enhancing user interaction and engagement.",
  "Crafting solutions with cutting-edge technology.",
  "Dedicated to continuous learning and improvement.",
  "Transforming complex problems into elegant solutions.",
  "Passionate about creating user-friendly designs.",
  "Bringing creativity and technology together.",
  "Focused on delivering high-quality code and performance.",
  "Committed to building scalable and efficient systems.",
  "Exploring new technologies to stay ahead of the curve.",
  "Striving to make the web a better place for everyone.",
  "Adapting to new challenges with enthusiasm and skill.",
  "Driven by a love for coding and problem-solving.",
  "Ensuring a seamless user experience through meticulous design.",
  "Innovating with a blend of creativity and technical expertise."
];

function Hero() {
  const [currentSentence, setCurrentSentence] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSentence(prev => (prev + 1) % sentences.length);
    }, 4000); // Change sentence every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Hi, my name is</h1>
        <h2>Haider Sana.</h2>
        <h3>
          {sentences.map((sentence, index) => (
            <span key={index} className={index === currentSentence ? 'active' : ''}>
              {sentence}
            </span>
          ))}
        </h3> 
        <br></br>
        <a href="#work" className="button">Check out my work!</a>
      </div>
      <img src={profileImage} alt="Haider Sana" className="profile-image" />
    </section>
  );
}

export default Hero;


