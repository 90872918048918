// src/components/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="social-media-links">
          <h2>Connect with Me</h2>
          <ul>
            <li>
              <FontAwesomeIcon icon={faLinkedin} />{' '}
              <a href="https://www.linkedin.com/in/haider-sana-853548248" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faGithub} />{' '}
              <a href="https://github.com/HaiderSana338" target="_blank" rel="noopener noreferrer">GitHub</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} />{' '}
              <a href="mailto:haidersana67@gmail.com">Email</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} />{' '}
              <a href="tel:+61432192796">Mobile</a>
            </li>
          </ul>
        </div>
        <div className="nav-links">
          <a href="#about" className="nav-link">About</a>
          <a href="#experience" className="nav-link">Experience</a>
          <a href="#work" className="nav-link">Work</a>
          <a href="#contact" className="nav-link">Contact</a>
          <a href="/resume.pdf" className="nav-link button" download="Haider_Sana_Resume.pdf">Resume</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

