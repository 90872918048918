// src/components/Experience.js
import React from 'react';

function Experience() {
  return (
    <section id="experience">
      <h2 className="numbered-heading">Where I've Worked</h2>
      <p>
        I have gained experience across various industries, from e-commerce to educational websites and social platforms. My skill set covers a range of technologies, allowing me to adapt to diverse project needs.
      </p>
      <p>
        <strong>Business Local</strong><br />
        Web Developer Intern (2024 - Present)<br />
        At Business Local, I work on developing and maintaining web pages and widgets. My responsibilities include enhancing site functionality using HTML, CSS, and JavaScript, as well as managing code within Brilliant Directories to improve user experience.
      </p>
    </section>
  );
}

export default Experience;
