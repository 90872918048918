// src/components/Contact.js
import React from 'react';

function Contact() {
  return (
    <section id="contact" className="section">
      <div className="contact-form-section">
        <div className="contact-form-box">
          <h2>Contact Me</h2>
          <form action="https://formspree.io/f/xjkbbdrj" method="POST">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
