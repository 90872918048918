// src/components/Skills.js
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faNodeJs, faAws, faCss3Alt, faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';

function Skills() {
  const skillsRef = useRef(null);


  return (
    <section id="skills" className="skills">
      <h2>My Skills</h2>
      <div className="skills-slider-container">
        <div className="skills-container" ref={skillsRef}>
          <div className="skill-item">
            <FontAwesomeIcon icon={faReact} size="2x" />
            <h3>React</h3>
            <p>Building dynamic and responsive user interfaces with React.</p>
          </div>
          <div className="skill-item">
            <FontAwesomeIcon icon={faNodeJs} size="2x" />
            <h3>Node.js</h3>
            <p>Server-side development using Node.js and Express.js.</p>
          </div>
          <div className="skill-item">
            <FontAwesomeIcon icon={faAws} size="2x" />
            <h3>AWS</h3>
            <p>Cloud computing and deployment using AWS services.</p>
          </div>
          <div className="skill-item">
            <FontAwesomeIcon icon={faCss3Alt} size="2x" />
            <h3>CSS</h3>
            <p>Styling and designing web pages with modern CSS techniques.</p>
          </div>
          <div className="skill-item">
            <FontAwesomeIcon icon={faHtml5} size="2x" />
            <h3>HTML</h3>
            <p>Creating structured and semantic HTML documents.</p>
          </div>
          <div className="skill-item">
            <FontAwesomeIcon icon={faCode} size="2x" />
            <h3>Programming</h3>
            <p>Proficient in various programming languages including Java, Python, and C++.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;


  