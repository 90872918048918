// src/Home.js
import React from 'react';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Skills from './Components/Skills';
import About from './Components/About';
import Experience from './Components/Experience';
import ProjectShowcase from './Components/ProjectShowcase';
import Work from './Components/Work';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Education from './Components/Education';
import AnimatedSection from './Components/AnimatedSection'; // Import the updated component


function Home() {
  return (
    <div>
      <Header />
      <main>
        <Hero />
        <AnimatedSection>
          <About />
        </AnimatedSection>
        <AnimatedSection>
          <Skills />
        </AnimatedSection>
        <AnimatedSection>
          <Experience />
        </AnimatedSection>
        <AnimatedSection>
          <Education />
        </AnimatedSection>
        <AnimatedSection>
          <ProjectShowcase />
        </AnimatedSection>
        <AnimatedSection>
          <Work />
        </AnimatedSection>
        <AnimatedSection>
          <Contact />
        </AnimatedSection>
        <Footer />
      </main>
    </div>
  );
}

export default Home;



