// src/Components/ProjectShowcase.js
import React, { useState, useEffect } from 'react';

// Import your images
import project1 from './Images/Trendall Home page.png';
import project2 from './Images/Trendall Gallery.png';
import project3 from './Images/Trendall Search pages.png';
import project4 from './Images/Trennbot.png';
import project5 from './Images/Trennbot 1.png';
import project6 from './Images/Trenn Bot 2.png';
import project7 from './Images/Traditional Search.png';
import project8 from './Images/Advance Search.png';
import project9 from './Images/Basic Search.png';
import project10 from './Images/Basic Search 1.png';
import project11 from './Images/Basic Search 2.png';
import project12 from './Images/Artifact Detail.png';
import project13 from './Images/QR Guard APP.png';
import project14 from './Images/QR Guard App 1.png';
import project15 from './Images/Smart Bin Project.png';
import project16 from './Images/Smart Bin Project 2.png';
import project17 from './Images/Smart Bin Web.png';
import project18 from './Images/Smart Bin Admin.png';
import project19 from './Images/Smart Bin Public.png';
import project20 from './Images/Smart Bin System Health.png';
import project21 from './Images/Smart Bin Maintenance.png';

const images = [
  project1, project2, project3, project4, project5,
  project6, project7, project8, project9, project10,
  project11, project12, project13, project14, project15,
  project16, project17, project18, project19, project20,
  project21
];

function ProjectShowcase() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <section className="project-showcase">
      <h2>Project Gallery</h2> {/* Add heading here */}
      <div className="carousel">
        <button className="carousel-button prev" onClick={prevSlide}>
          &lt;
        </button>
        <div className="carousel-slides">
          <img src={images[currentIndex]} alt={`Project ${currentIndex + 1}`} />
        </div>
        <button className="carousel-button next" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </section>
  );
}

export default ProjectShowcase;












