import React from 'react';
import trendallImage from '../Components/Images/Trendall Home page.png';
import qrGuardImage from '../Components/Images/QR Guard APP.png';
import reactContactImage from '../Components/Images/Contact App.png';

function Work() {
  const projects = [
    {
      title: 'Trendall Research Centre',
      role: 'Web Developer',
      year: '2024',
      description: [
        'Designed and developed a research collaboration platform.',
        'Built using React.js and Node.js.',
        'Enhanced accessibility and scholarly interaction.'
      ],
      imageUrl: trendallImage
    },
    {
      title: 'QR Guard App',
      role: 'Android Developer',
      year: '2024',
      description: [
        'Developed a personal safety app using QR code technology.',
        'Built using Java and Android Studio.',
        'Focused on protecting valuables and enhancing personal security.'
      ],
      imageUrl: qrGuardImage
    },
    {
      title: 'React Contact App',
      role: 'React.js Developer',
      year: '2023',
      description: [
        'Developed a scalable contact management app.',
        'Frontend built with React.js, backend with Express.js.',
        'Used PostgreSQL for data management and Docker for deployment.'
      ],
      imageUrl: reactContactImage
    }
  ];

  return (
    <section id="work">
      <h2 className="numbered-heading">Some Things I've Built</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <div key={index} className="project">
            <img src={project.imageUrl} alt={project.title} className="project-image" />
            <div className="project-details">
              <h3>{project.title}</h3>
              <p>{project.role} • {project.year}</p>
              <ul>
                {project.description.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Work;

